import React, {ReactNode} from 'react';
import s from './Button.module.scss';
import classNames from "classnames";

interface ButtonProps {
    type?: 'contained' | 'outlined';
    children?: ReactNode;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    role?: "button" | "submit" | "reset" | undefined
}

export default function Button({ type = 'contained', children, className = '', onClick, role = "button" }: ButtonProps) {
    return (
        <div>
            <button
                onClick={onClick}
                className={classNames(
                    s.button,
                    {
                        [s.button__contained]: type === 'contained',
                        [s.button__outlined]: type === 'outlined',
                    },
                    className
                )}
                type={role}
            >
                {children}
            </button>
        </div>
    );
}
