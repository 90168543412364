import React, {useMemo} from 'react';

import s from './AsideLayout.module.scss'
import {Link, useLocation, useParams} from "react-router-dom";
import classNames from "classnames";

export default function AsideLayout() {
    const location = useLocation()
    const {id, leadId} = useParams()

    const sectionsData = useMemo(() => {
        return [
            {
                text: 'По Времени',
                url: leadId ? `/reports/time/lead/${leadId}` : id ? `/reports/time/${id}` : `/reports/time`
            },
            {
                text: 'По Офферам',
                url: leadId ? `/reports/offers/lead/${leadId}` : id ? `/reports/offers/${id}` : `/reports/offers`
            }, {
                text: 'По Конверсии',
                url: leadId ? `/reports/conversion/lead/${leadId}` : id ? `/reports/conversion/${id}` : `/reports/conversion`
            }
        ]
    }, [id])

    return (
        <aside className={s.aside}>
            <div className={s.aside__content}>
                {sectionsData.map((item) => (
                    <Link to={item.url} className={classNames({
                        [s.aside__content__text]: true,
                        [s.aside__content__text__active]: location.pathname === item.url
                    })}>{item.text}</Link>
                ))}
            </div>
        </aside>
    )
}
