import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import SectionIcon from "../../../../../assets/icons/SectionIcon";
import { FooterItemType } from "../../../../../types/FooterItemType";
import s from './FooterItem.module.scss';

interface FooterItemProps {
    isProfileVisible: boolean;
    data: FooterItemType;
    setIsProfileVisible: Dispatch<SetStateAction<boolean>>;
}

export default function FooterItem({ data, setIsProfileVisible, isProfileVisible }: FooterItemProps) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        setIsActive(location.pathname === data.route);
    }, [location.pathname, data.route]);

    const handleClick = () => {
        if (data.menu) {
            setIsProfileVisible(!isProfileVisible);
        } else {
            setIsProfileVisible(false);
        }
    }

    return (
        <NavLink to={data?.route!} type={"button"} onClick={handleClick} className={s.footerItem} key={data.id}>
            <div className={s.footerItem_icon}>
                <SectionIcon isActive={isActive || (isProfileVisible && data.menu )} />
            </div>
            <div
                className={classNames(s.footerItem_title, {
                    [s.footerItem_title_active]: isActive || (isProfileVisible && data.menu ),
                })}
            >
                {data.title}
            </div>
        </NavLink>
    );
}
