import React from 'react';
import s from './Loader.module.scss';
import classNames from "classnames";
import loader from '../../../assets/icons/loader.svg'

export default function Loader() {
    return (
        <img src={loader} alt="" className={classNames({
            [s.loader]: true,
        })}/>
    );
}
