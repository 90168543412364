import React, {Fragment, useEffect, useRef, useState} from 'react';
import s from './CommunicationPage.module.scss';
import {toast} from "react-toast";
import {ITableDataHeader} from "../../../components/UI/Table/Table";
import {Offers} from "../../../api/interface/Offers";
import {ENDPOINTS} from "../../../api/endpoints";
import PageHeader from "../../../components/PageHeader";
import SearchInput from "../../../components/UI/Inputs/SearchInput";
import Table from "../../../components/UI/Table";
import Loader from "../../../components/UI/Loader";
import TableRow from "../../../components/UI/Table/components/TableRow";
import TableColumn from "../../../components/UI/Table/components/TableColumn/TableColumn";
import FormInput from "../../../components/UI/Inputs/FormInput";
import {useParams} from "react-router-dom";
import {Links} from "../../../api/interface/Links";
import {Helmet} from "react-helmet";

interface OfferLink {
    offer: Offers,
    link?: Links | undefined
}

export default function CommunicationPage() {

    const tableGrid = ['auto', '160px', '260px', '100px', '100px'];
    const headerData: ITableDataHeader[] = [
        {title: 'Название'},
        {title: 'Цена'},
        {title: 'Ссылка'},
    ];

    const [offers, setOffers] = useState<OfferLink[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(true)

    const [name, setName] = useState<string>('');

    const currentItems = offers.filter(o => {
        return o.offer.title ? o.offer.title.toLowerCase().indexOf(name.toLowerCase()) !== -1 : true
    });

    const params = useParams()

    useEffect(() => {
        if (params.id) {
            ENDPOINTS.OFFERS.GET().then((_offers) => {
                if (params.id) {
                    ENDPOINTS.LINKS.GET_BY_ID_USER(parseInt(params.id)).then((_links) => {
                        setOffers(_offers.map((_offer): OfferLink => {
                            return {
                                offer: _offer,
                                link: _links.find((_link) => (_link.offer.id === _offer.id))
                            }
                        }))

                        setIsLoading(false)
                    })
                }
            })
        }
    }, []);

    const saveOffer = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (params.id) {
            const formData = new FormData(e.currentTarget)

            ENDPOINTS.LINKS.CREATE_OR_UPDATE({
                price: formData.get('price'),
                original_link: formData.get('original_link'),
                offer_id: formData.get('offer_id'),
                user_id: params.id,
            }).then((res) => {
                setOffers((prevState) => {
                    return prevState.map((offer) => {

                        if (offer.offer.id.toString() === formData.get('offer_id')) {
                            return {
                                offer: offer.offer,
                                link: res
                            }
                        }

                        return offer
                    })
                })
                toast.success('Успешно обновлено.')
            }).catch((e) => {
                if (e.response.data.message[0].constraints.isUrl) {
                    toast.error('Упс. Кажется ваша ссылка не поддерживается')
                } else {
                    toast.error('Упс. Что-то пошло не так, попробуйте позже.')
                }
            })
        }
    }

    const deleteLink = (id?: number) => {
        if (id) {
            const ready = window.confirm('Вы уверены? Данные будут стерты навсегда')
            if (ready) {
                ENDPOINTS.LINKS.REMOVE(id).then(() => {
                    setOffers((prevState) => {
                        return prevState.map((offer) => {

                            if (offer?.link?.id === id) {
                                return {
                                    offer: offer.offer,
                                    link: undefined
                                }
                            }

                            return offer
                        })
                    })
                    toast.success('Успешно удалено.')
                }).catch(() => toast.error('Упс. Что-то пошло не так, попробуйте позже.'))
            }
        }
    }

    function checkUrl(target: EventTarget & HTMLInputElement) {
        const value = target.value;
        const query = new URLSearchParams(value)

        if (query.get('sub5')) {
            if (!target.classList.contains('error-sub5')) {
                toast.error('В вашей ссылке есть sub5 параметр. Он нужен для отслеживания, пожалуйста уберите его.')
            }
            target.classList.add('error-sub5')
            target.setAttribute('title', 'В вашей ссылке есть sub5 параметр. Он нужен для отслеживания, пожалуйста уберите его.')
            setIsError(true)
        } else {
            target.classList.remove('error-sub5')
            target.removeAttribute('title')
            setIsError(false)
        }
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>{"Добавить офферы для пользователя: " + params.id} | Shark Cpa</title>
            </Helmet>
            <PageHeader title={"Добавить офферы для пользователя: " + params.id} childrenPosition={"end"}>
                <SearchInput onChange={(e) => setName(e.currentTarget.value)} value={name}/>
            </PageHeader>

            <div className={s.container__products}>
                <Table dataHeaders={headerData} className={s.container__products_table} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {currentItems.map((item, index) => (
                        <Fragment key={index}>
                            <form onSubmit={saveOffer}>
                                <TableRow gridWidthSize={tableGrid}>
                                    <TableColumn>
                                        <div className={s.title}><img src={item.offer.logo} alt={"card"}/>
                                            <span>{item.offer.title}</span></div>
                                    </TableColumn>
                                    <TableColumn>
                                        <FormInput name={"price"} type={"number"} defaultValue={item?.link?.price}/>
                                        <input type={'hidden'} name={"offer_id"} value={item.offer.id}/>
                                    </TableColumn>
                                    <TableColumn>
                                        <FormInput name={"original_link"} type={"text"} onChange={(e) => {
                                            checkUrl(e.currentTarget)
                                        }}
                                                   defaultValue={item?.link?.original_link}/>
                                    </TableColumn>
                                    <TableColumn>
                                        {item?.link && <button type={"button"} onClick={(e) => {
                                            e.preventDefault()
                                            deleteLink(item?.link?.id)
                                        }} className={s.hoverItem}>Удалить</button>}
                                    </TableColumn>
                                    <TableColumn>
                                        <button type={"submit"} disabled={isError} className={s.hoverItem}>Сохранить</button>
                                    </TableColumn>
                                </TableRow>
                            </form>
                        </Fragment>
                    ))}
                </Table>
                {isLoading && <Loader/>}
            </div>
        </div>
    )
}
