import React from 'react';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Provider} from "react-redux";
import Router from "./Router";
import {store} from "./store";
import {ToastContainer} from "react-toast";
import AuthProvider from "./providers/AuthProvider";

export const queryClient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <AuthProvider>
                    <Router/>
                    <ToastContainer delay={3000} position={'bottom-right'}/>
                </AuthProvider>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
