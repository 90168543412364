import React, {ReactNode} from 'react';

import classNames from "classnames";

import s from './PageHeader.module.scss'
import {Link} from "react-router-dom";

interface PageHeaderProps {
    title: string;
    childrenPosition?: 'start' | 'end';
    create?: () => void;
    text?: string;
    url?: string;
    children?: ReactNode;
}

export default function PageHeader({url = '',title, text, children, create, childrenPosition}: PageHeaderProps) {
    return (
        <div className={s.pageHeader}>
            <div className={s.pageHeader__leftPart}>
                <div className={s.pageHeader__leftPart__title}>
                    {title}
                </div>
                {(url || create && text) && <Link to={url}>
                    <button className={s.pageHeader__leftPart__button} onClick={create}>
                    <span className={s.pageHeader__leftPart__button__text}>
                        {text}
                    </span>
                    </button>
                </Link> }
            </div>
            {children && (
                <div className={classNames({
                    [s.pageHeader__wrapper]: true,
                    [s.pageHeader__wrapper_start]: childrenPosition === 'start',
                    [s.pageHeader__wrapper_end]: childrenPosition === 'end',
                })}>
                    {children}
                </div>
            )}
        </div>
    )
}
