import React, {Fragment, useEffect, useState} from 'react';
import s from './LeadsPage.module.scss';
import PageHeader from "../../components/PageHeader";
import SearchInput from "../../components/UI/Inputs/SearchInput";
import Table from "../../components/UI/Table";
import TableRow from "../../components/UI/Table/components/TableRow";
import TableColumn from "../../components/UI/Table/components/TableColumn/TableColumn";
import {Link} from "react-router-dom";
import {ITableDataHeader} from "../../components/UI/Table/Table";
import {ENDPOINTS} from "../../api/endpoints";
import {Leads} from "../../api/interface/Leads";
import {Helmet} from "react-helmet";

export default function LeadsPage() {
    const [name, setName] = useState<string>('');

    const tableGrid = ['1fr', '160px', '160px'];
    const headerData: ITableDataHeader[] = [
        {title: 'ФИО'},
        {title: ''},
    ];

    const [leads, setLeads] = useState<Leads[]>([])

    useEffect(() => {
        ENDPOINTS.LEADS.GET().then(res => {
            setLeads(res)
        })
    }, []);

    const currentItems = leads.filter(o => {
        return o.username ? o.username.toLowerCase().indexOf(name.toLowerCase()) !== -1 : true
    });

    return (
        <div className={s.container}>
            <Helmet>
                <title>Лиды | Shark Cpa</title>
            </Helmet>
            <PageHeader title={"Лиды"} text="Создать" url={"/leads/create/new"} create={() => {
            }} childrenPosition={"end"}>
                <SearchInput placeholder={"Поиск по ФИО"} onChange={(e) => setName(e.currentTarget.value)}
                             value={name}/>
            </PageHeader>

            <div className={s.container__products}>
                <Table dataHeaders={headerData} className={s.container__products_table} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {currentItems.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow url={`/leads/${item.id}`}
                                      gridWidthSize={tableGrid} hover={true}>
                                <TableColumn>
                                    <div className={s.title}>{item.username}</div>
                                </TableColumn>
                                <TableColumn>
                                    <Link
                                        to={`/reports/time/lead/${item.id}`}
                                        className={s.hoverItem}>Статистика</Link>
                                </TableColumn>
                                <TableColumn>
                                    <Link
                                        to={`/leads/${item.id}`}
                                        className={s.hoverItem}>Смотреть</Link>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}

                </Table>
            </div>
        </div>
    )
}
