import React from 'react';
import s from './TablePagination.module.scss';
import KeyboardArrowDownRounded from "../../../../../assets/icons/KeyboardArrowDownRounded";

interface TablePaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

export default function TablePagination({ currentPage, totalPages, onPageChange }: TablePaginationProps) {
    const maxDots = 3;

    const getPageNumbers = () => {
        const paginationDots = [];

        if (totalPages <= maxDots) {
            for (let i = 1; i <= totalPages; i++) {
                paginationDots.push(
                    <button key={i} onClick={() => onPageChange(i)} className={i === currentPage ? s.active : ''}>
                        {i}
                    </button>
                );
            }
        } else {
            let start = Math.max(1, currentPage - Math.floor(maxDots / 2));
            let end = Math.min(totalPages, start + maxDots - 1);

            if (end - start < maxDots - 1) {
                start = Math.max(1, end - maxDots + 1);
            }

            if (start > 1) {
                paginationDots.push(
                    <button key={1} onClick={() => onPageChange(1)}>
                        1
                    </button>
                );
                if (start > 2) {
                    paginationDots.push(
                        <button key="leftDots" className={s.dots}>
                            ...
                        </button>
                    );
                }
            }

            for (let i = start; i <= end; i++) {
                paginationDots.push(
                    <button key={i} onClick={() => onPageChange(i)} className={i === currentPage ? s.active : ''}>
                        {i}
                    </button>
                );
            }

            if (end < totalPages) {
                if (end < totalPages - 1) {
                    paginationDots.push(
                        <button key="rightDots" className={s.dots}>
                            ...
                        </button>
                    );
                }
                paginationDots.push(
                    <button key={totalPages} onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                );
            }
        }

        return paginationDots;
    };

    return (
        <div className={s.tablePagination}>
            <div className={s.content}>
                <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className={s.leftArrow}>
                    <KeyboardArrowDownRounded className={s.icon}/>
                </button>
                {getPageNumbers()}
                <button onClick={() => onPageChange(currentPage + 1)} className={s.rightArrow} disabled={currentPage === totalPages}>
                    <KeyboardArrowDownRounded className={s.icon}/>
                </button>
            </div>
        </div>
    );
}
