import React from 'react';

export default function KeyboardArrowDownRounded(props: React.SVGProps<SVGSVGElement>) {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.80695 5.41348L7.22029 8.00014L9.80695 10.5868C10.067 10.8468 10.067 11.2668 9.80695 11.5268C9.54695 11.7868 9.12695 11.7868 8.86695 11.5268L5.80695 8.46681C5.54695 8.20681 5.54695 7.78681 5.80695 7.52681L8.86695 4.46681C9.12695 4.20681 9.54695 4.20681 9.80695 4.46681C10.0603 4.72681 10.067 5.15348 9.80695 5.41348Z"
                fill="black"/>
        </svg>
    );
}
