import {createContext, ReactNode, useEffect, useState} from "react";
import Cookies from "js-cookie";
import {store, useAppDispatch, useAppSelector} from "../store";
import {setProfile} from "../store/reducers/profileReducer";
import {ENDPOINTS} from "../api/endpoints";
import authReducer from "../store/reducers/authReducer";
import {useSelector} from "react-redux";

export type AuthContextType = {};
export const AuthContext = createContext<AuthContextType>({});

type AuthProviderProps = {
    children: ReactNode;
}

const Provider = ({children}: AuthProviderProps) => {
    const token = Cookies.get('token')
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)

    useEffect(() => {
        if(token) {
            ENDPOINTS.PROFILE.GET_iNFO().then((res) => {
                if (res) {
                    dispatch(setProfile(res))
                }
            })
        }
    }, [isAuthenticated])

    return (
        <AuthContext.Provider value={{}}>
            {children}
        </AuthContext.Provider>
    )
}

export default Provider;