import React, {ReactNode, useState} from 'react';
import s from './TableRow.module.scss';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

interface TableRowProps {
    children: ReactNode;
    gridWidthSize?: Array<string | number>;
    isClickable?: boolean;
    onClick?: () => void;
    hover?: boolean;
    hoverContent?: ReactNode;
    url?: string;
}

function TableRow({
                      children,
                      hover = false,
                      gridWidthSize = [],
                      url = '',
                      isClickable = false,
                      onClick = () => {
                      },
                      hoverContent,
                  }: TableRowProps) {
    const [isHovered, setIsHovered] = useState(false);

    const handleHoverContentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        onClick();
    };

    return (
        <>{url ?
            <Link to={url}>
                <div
                    className={classNames({
                        [s.row]: true,
                        [s.usuallyRow]: hover,
                        [s.rowHovered]: isHovered,
                        [s.clickable]: isClickable,
                    })}
                    onClick={onClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                        gridTemplateColumns: gridWidthSize?.join(' '),
                    }}
                >
                    {React.Children.map(children, (child, index) => (
                        <div className={s.titleContainer}>{child}</div>
                    ))}
                    {isHovered && hoverContent && (
                        <div className={s.hoverContent} onClick={handleHoverContentClick}>
                            {hoverContent}
                        </div>
                    )}
                </div>
            </Link> :
            <div
                className={classNames({
                    [s.row]: true,
                    [s.usuallyRow]: hover,
                    [s.rowHovered]: isHovered,
                    [s.clickable]: isClickable,
                })}
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    gridTemplateColumns: gridWidthSize?.join(' '),
                }}
            >
                {React.Children.map(children, (child, index) => (
                    <div className={s.titleContainer}>{child}</div>
                ))}
                {isHovered && hoverContent && (
                    <div className={s.hoverContent} onClick={handleHoverContentClick}>
                        {hoverContent}
                    </div>
                )}
            </div>

        }
        </>
    );
}

export default TableRow;
