import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import profileReducer from "./reducers/profileReducer";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        profile: profileReducer,
    },
})

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;


export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
