import React, {FormEvent} from 'react';

import s from './RegistrationPage.module.scss'
import FormInput from "../../components/UI/Inputs/FormInput";
import Button from "../../components/UI/Button";
import {useDispatch} from "react-redux";
import {setIsAuthenticated} from "../../store/reducers/authReducer";
import {ENDPOINTS} from "../../api/endpoints";
import Cookies from "js-cookie";
import {AxiosError} from "axios";
import {toast} from "react-toast";
import {Helmet} from "react-helmet";

export default function RegistrationPage () {
    const dispatch = useDispatch()

    async function registerClick(e: FormEvent<HTMLFormElement>) {
        try {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const {data} = await ENDPOINTS.AUTH.REGISTER({
                email: formData.get('email'),
                password: formData.get('password')
            })

            if(data.access_token) {
                Cookies.set('token', data.access_token);
                dispatch(setIsAuthenticated(true))
            }
        } catch (e: AxiosError | any) {
            if(e.response.data.message[0].property === "password") {
                return toast.error('Пароль должен содержать: Минимум 8 символов, 1 маленькую букву, 1 заглавную, 1 цифру и 1 спец. символ')
            }

            toast.error('Упс. Что-то не так. Попробуйте позже')
            console.error(e)
        }
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>Регистрация | Shark Cpa</title>
            </Helmet>
            <form className={s.container__content} onSubmit={registerClick}>
                <div className={s.container__content__inputs}>
                    <FormInput text="Email" type="email" name={"email"}/>
                    <FormInput text="Пароль" type="password" name={"password"}/>
                </div>
                <div className={s.container__content__buttons}>
                    <Button role={"submit"} type="contained" className={s.container__buttons} >
                        Зарегистрироваться
                    </Button>
                    <Button onClick={() => {window.location.href = '/login'}} type="outlined">
                        Авторизация
                    </Button>
                </div>
            </form>

        </div>
    )
}
