import {Navigate, useLocation} from "react-router-dom";
import {Outlet} from "react-router";
import {useAppSelector} from "../../store";

export const PrivateRoute = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();

  return (isAuthenticated ? <Outlet/> : <Navigate to="/login" state={{from: location}} replace/>);
}
