import React from 'react';
import {Link, useLocation} from "react-router-dom";
import classNames from "classnames";

import s from './NavLink.module.scss'
import SectionIcon from "../../../../../assets/icons/SectionIcon";

interface NavLinkProps {
    text: string;
    url: string;
}

export default function NavLink({text, url}: NavLinkProps) {

    const location = useLocation();

    const isActive = location.pathname === url;

    return (
        <div>
            <Link to={url}>
                <div className={classNames({
                    [s.navLink]: true,
                })}>
                    <div className={s.navLink__leftPart}>
                        <SectionIcon isActive={isActive}/>
                    </div>
                    <div className={s.navLink__rightPart}>
                        <span className={classNames({
                            [s.navLink__rightPart__text]: true,
                            [s.active]: isActive,
                        })}>
                            {text}
                        </span>
                    </div>
                </div>
            </Link>
        </div>
    )
}
