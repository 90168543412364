import React, {ReactNode, forwardRef, ForwardedRef, InputHTMLAttributes, useState} from 'react';
import s from './FormInput.module.scss';

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
    children?: ReactNode;
    text?: string;
    name?: string
}

const FormInput = forwardRef((props: FormInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {children, text, name, ...rest} = props;
    const [value, setValue] = useState(props.defaultValue || '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div className={s.formInput}>
            <span>{text}</span>
            <div className={s.formInput__container}>
                <input
                    {...rest}
                    ref={ref}
                    className={s.formInput__container__input}
                    value={value}
                    onChange={handleChange}
                    name={name}
                />
                {children &&
                    <div className={s.formInput__container__child}>
                        {children}
                    </div>
                }
            </div>
        </div>
    );
});

export default FormInput;
