import React from 'react';

export default function ErrorPage () {
    return (
        <div>
            <h2>Данной страницы не существует</h2>
            <br/>
            <a href={"/"}>На главную</a>
        </div>
    )
}
