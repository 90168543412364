import React from 'react';
import PropTypes from 'prop-types';
import s from './SearchInput.module.scss';
import SearchIcon from '../../../../assets/icons/SearchIcon';

interface SearchInputProps {
    value: string;
    placeholder?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchInput({ value, onChange, placeholder = "Поиск по названию" }: SearchInputProps) {
    return (
        <div className={s.searchInput}>
            <div className={s.searchInput__input}>
                <input
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className={s.input}
                />
                <SearchIcon className={s.icon}/>
            </div>
        </div>
    );
}

SearchInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
