import React, {useMemo, useState} from "react";
import {footerData} from "../../../constants/FooterItems";
import FooterItem from "./components/FooterItem";

import s from './FooterLayout.module.scss'
import FooterProfile from "./components/FooterProfile";

export default function FooterLayout() {
    const [isProfileVisible, setIsProfileVisible] = useState<boolean>(false)

    const footerItemComp = useMemo(() => {
        return footerData.map((item) => (
            <FooterItem key={item.id} data={item} isProfileVisible={isProfileVisible} setIsProfileVisible={setIsProfileVisible}/>
        ));
    }, [isProfileVisible]);

    return (
        <div className={s.footer}>
            <div className={s.footer__content}>
                {isProfileVisible && <FooterProfile/>}
                <div className={s.footer__items}>
                    {footerItemComp}
                </div>
            </div>
        </div>
    );
}
