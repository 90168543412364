import React from 'react';

export default function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.1207 9.28957L12.0007 13.1696L15.8807 9.28957C16.2707 8.89957 16.9007 8.89957 17.2907 9.28957C17.6807 9.67957 17.6807 10.3096 17.2907 10.6996L12.7007 15.2896C12.3107 15.6796 11.6807 15.6796 11.2907 15.2896L6.7007 10.6996C6.3107 10.3096 6.3107 9.67957 6.7007 9.28957C7.0907 8.90957 7.7307 8.89957 8.1207 9.28957Z"
                fill="url(#paint0_linear_421_2866)"/>
            <defs>
                <linearGradient id="paint0_linear_421_2866" x1="6.4082" y1="12.2896" x2="17.5832" y2="12.2896"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#73499E"/>
                    <stop offset="1" stop-color="#F84560"/>
                </linearGradient>
            </defs>
        </svg>
    );
}
