import s from './FooterProfile.module.scss'
import {useAppSelector} from "../../../../../store";
import {setIsAuthenticated} from "../../../../../store/reducers/authReducer";
import Cookies from "js-cookie";
import {useDispatch} from "react-redux";

export default function FooterProfile() {
    const {email, balance} = useAppSelector((state) => state.profile)

    const dispatch = useDispatch()

    const logout = () => {
        dispatch(setIsAuthenticated(false));
        Cookies.remove('token')
        window.location.href = '/';
    }

    return (
        <div className={s.footerProfile}>
            <div className={s.footerProfile_leftPart}>
                <span className={s.footerProfile_leftPart_email}>
                    {email}
                </span>
                <span className={s.footerProfile_leftPart_balance}>
                    Баланс: {balance} ₽
                </span>
            </div>
            <div className={s.footerProfile_rightPart}>
                <button onClick={logout} className={s.footerProfile_rightPart_exit}>
                    Выйти
                </button>
            </div>
        </div>
    )
}