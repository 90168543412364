import React from 'react';

export default function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.3338 9.83375H9.80708L9.62042 9.65375C10.4204 8.72042 10.8338 7.44708 10.6071 6.09375C10.2938 4.24042 8.74708 2.76042 6.88042 2.53375C4.06042 2.18708 1.68708 4.56042 2.03375 7.38042C2.26042 9.24708 3.74042 10.7938 5.59375 11.1071C6.94708 11.3338 8.22042 10.9204 9.15375 10.1204L9.33375 10.3071V10.8338L12.1671 13.6671C12.4404 13.9404 12.8871 13.9404 13.1604 13.6671C13.4338 13.3938 13.4338 12.9471 13.1604 12.6738L10.3338 9.83375ZM6.33375 9.83375C4.67375 9.83375 3.33375 8.49375 3.33375 6.83375C3.33375 5.17375 4.67375 3.83375 6.33375 3.83375C7.99375 3.83375 9.33375 5.17375 9.33375 6.83375C9.33375 8.49375 7.99375 9.83375 6.33375 9.83375Z"
                fill="#949DA5"/>
        </svg>

    );
}
