import React, {Fragment, useEffect, useRef, useState} from 'react';

import s from './UserPage.module.scss'
import FormInput from "../../../components/UI/Inputs/FormInput";
import Button from "../../../components/UI/Button";
import PageHeader from "../../../components/PageHeader";
import {ITableDataHeader} from "../../../components/UI/Table/Table";
import Table from "../../../components/UI/Table";
import TableRow from "../../../components/UI/Table/components/TableRow";
import TableColumn from "../../../components/UI/Table/components/TableColumn/TableColumn";
import {useParams} from "react-router-dom";
import TablePagination from "../../../components/UI/Table/components/TablePagination";
import {UserInfo} from "../../../api/interface";
import {ENDPOINTS} from "../../../api/endpoints";
import {toast} from "react-toast";
import Loader from "../../../components/UI/Loader";
import {Links} from "../../../api/interface/Links";
import {Helmet} from "react-helmet";

export default function UserPage() {
    const tableGrid = ['1fr', '100px'];

    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(true)
    const [isLoadingLinks, setIsLoadingLinks] = useState<boolean>(true)
    const [profile, setProfile] = useState<UserInfo | undefined>(undefined)
    const [links, setLinks] = useState<Links[]>([])

    const headerData: ITableDataHeader[] = [
        {title: 'Название'},
        {title: 'Цена'},
    ];

    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 8;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = links.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(links.length / itemsPerPage);
    const formRef = useRef<HTMLFormElement>(null)

    const params = useParams()

    useEffect(() => {
        if (params.id) {
            ENDPOINTS.USERS.GET_BY_ID(parseInt(params.id)).then((res) => {
                setProfile(res)
                setIsLoadingProfile(false)
            }).catch(() => toast.error('Не смогли получить информацию о профиле.'))

            ENDPOINTS.LINKS.GET_BY_ID_USER(parseInt(params.id)).then(res => {
                setLinks(res)
                setIsLoadingLinks(false)
            })
        } else {
            toast.error('Не смогли получить информацию о профиле.')
        }
    }, [params.id]);

    const updateUser = async () => {
        if (formRef.current && params.id) {
            const formData = new FormData(formRef.current)
            ENDPOINTS.USERS.UPDATE({
                id: parseInt(params.id),
                balance: formData.get('balance') || undefined,
                email: formData.get('email') || undefined
            }).then((res) => {
                toast.success('Успешно обновлено')
            }).catch(() => {
                toast.error('Упс. Что-то пошло не так, попробуйте позже.')
            })
            console.log(formRef.current)
        }
    }

    const deleteUser = async () => {
        if (params.id) {
            const ready = window.confirm('Вы уверены? Данные будут стерты навсегда')
            if (ready) {
                ENDPOINTS.USERS.REMOVE(parseInt(params.id)).then((res) => {
                    toast.success('Успешно удален')
                    window.location.replace("/users");
                }).catch(() => {
                    toast.error('Упс. Что-то пошло не так, попробуйте позже.')
                })
            }
        }
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>{profile ? profile.email : "Пользователь"} | Shark Cpa</title>
            </Helmet>
            <div className={s.container__content}>
                <div className={s.container__content__leftPart}>
                    <form className={s.container__content__leftPart__inputs} ref={formRef}>
                        {profile && <>
                            <FormInput text="Почта" name={"email"} type={"email"} defaultValue={profile.email}/>
                            <FormInput text="Баланс" name={"balance"} type={"number"} defaultValue={profile.balance}/>
                        </>}
                        {isLoadingProfile && <Loader/>}
                    </form>
                    <div className={s.container__content__leftPart__buttons}>
                        {profile && <>
                            <Button onClick={updateUser} type={"contained"}>
                                Сохранить
                            </Button>
                            {profile.rights !== 'ADMIN' && <Button onClick={deleteUser} type={"outlined"}>
                                <span>Удалить</span>
                            </Button>}
                        </>}
                    </div>
                </div>
                <div className={s.container__content__rightPart}>
                    <PageHeader text="Редактировать" title="Офферы" url={`create`}/>

                    <div className={s.container__rightPart__products}>
                        <Table dataHeaders={headerData} className={s.container__rightPart__products_table} gridWidthSize={tableGrid}>
                            {headerData && <div className={s.underlined}/>}
                            {currentItems.map((item, index) => (
                                <Fragment key={index}>
                                    <TableRow gridWidthSize={tableGrid}>
                                        <TableColumn>
                                            <div className={s.title}><img src={item.offer.logo} alt={"card"}/>
                                                <span>{item.offer.title}</span></div>
                                        </TableColumn>
                                        <TableColumn>
                                            <div>{item.price} ₽</div>
                                        </TableColumn>
                                    </TableRow>
                                </Fragment>
                            ))}

                        </Table>
                        {isLoadingLinks && <Loader/>}
                    </div>
                    <TablePagination
                        onPageChange={(page: number) => setCurrentPage(page)}
                        currentPage={currentPage}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </div>
    )
}
