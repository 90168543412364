import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import Cookies from "js-cookie";

interface AuthState {
    isAuthenticated: boolean
}

const initialState: AuthState = {
    isAuthenticated: !!Cookies.get('token') || false
}

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload
        },
    },
})

export const {setIsAuthenticated} = AuthSlice.actions

export default AuthSlice.reducer