import React, {FormEvent} from 'react';

import s from './LoginPage.module.scss'
import FormInput from "../../components/UI/Inputs/FormInput";
import Button from "../../components/UI/Button";
import {useDispatch} from "react-redux";
import {setIsAuthenticated} from "../../store/reducers/authReducer";
import {ENDPOINTS} from "../../api/endpoints";
import Cookies from "js-cookie";
import {toast} from "react-toast";
import {AxiosError} from "axios";
import {Helmet} from "react-helmet";

export default function LoginPage () {
    const dispatch = useDispatch()

    async function loginClick(e: FormEvent<HTMLFormElement>) {
        try {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const {data} = await ENDPOINTS.AUTH.LOGIN({
                email: formData.get('email'),
                password: formData.get('password')
            })

            if(data.access_token) {
                Cookies.set('token', data.access_token);
                dispatch(setIsAuthenticated(true))
            }
        } catch (e: AxiosError | any) {
            toast.error('Упс. Что-то не так. Проверьте почту и пароль или попробуйте позже')
            console.error(e)
        }
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>Авторизация | Shark Cpa</title>
            </Helmet>
            <form className={s.container__content} onSubmit={loginClick}>
                <div className={s.container__content__inputs}>
                    <FormInput text="Email" type="email" name={"email"}/>
                    <FormInput text="Пароль" type="password" name={"password"}/>
                </div>
                <div className={s.container__content__buttons}>
                    <Button type="contained" className={s.container__buttons} role={'submit'}>
                        Войти
                    </Button>
                    <Button onClick={() => {window.location.href = '/registration'}} type="outlined">
                        Регистрация
                    </Button>
                </div>
            </form>

        </div>
    )
}
