import React from 'react';

import s from './StatusBadge.module.scss';

interface StatusBadgeProps {
    status: string;
}

export default function StatusBadge ({ status }: StatusBadgeProps) {
    let color = '#333333';
    switch (status) {
        case 'На проверке':
            color = '#FFD568';
            break;
        case 'Отклонено':
            color = '#FF6868';
            break;
        case 'Одобрено':
            color = '#68FF77';
            break;
        default:
            break;
    }

    return (
        <div className={s.statusContainer}>
            <div className={s.statusContainer__circle} style={{ backgroundColor: color }} />
            <div className={s.statusContainer__text}>{status}</div>
        </div>
    )
}
