import React, {Fragment, useEffect, useState} from 'react';
import s from './OffersCreatePage.module.scss';
import PageHeader from "../../components/PageHeader";
import SearchInput from "../../components/UI/Inputs/SearchInput";
import Table from "../../components/UI/Table";
import {ITableDataHeader} from "../../components/UI/Table/Table";
import TableColumn from "../../components/UI/Table/components/TableColumn/TableColumn";
import TableRow from "../../components/UI/Table/components/TableRow";
import {ENDPOINTS} from "../../api/endpoints";
import {toast} from "react-toast";
import Loader from "../../components/UI/Loader";
import {Helmet} from "react-helmet";
import TablePagination from "../../components/UI/Table/components/TablePagination";
import {DataXPartnersOffers} from "../../api/interface/Unicom";

export default function OffersCreatePage() {
    const DATA_PAGE_LIMIT: number = 20
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [search, setSearch] = useState<string>('');
    const [searchString, setSearchString] = useState<string>('');

    const [rawData, setRawData] = useState<DataXPartnersOffers>({
        data: [],
        pagination: {
            count: 0,
            limit: DATA_PAGE_LIMIT,
            page: currentPage
        }
    })

    const tableGrid = ['120px', '1fr', '160px'];
    const headerData: ITableDataHeader[] = [
        {title: 'ID'},
        {title: 'Название'},
        {title: ''},
    ];


    useEffect(() => {
        getData(currentPage)
    }, [currentPage, searchString]);

    useEffect(() => {
        const timeOutId = setTimeout(() => setSearchString(search), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);


    async function createOrRemoveOffer(id: number) {
        await ENDPOINTS.OFFERS.CREATE_OR_REMOVE(id)
        toast.success('Успешно выполнено')

        setRawData((prevState) => {
            const data = prevState.data.map((offer) => {
                if (offer.id === id) {
                    return {
                        id: offer.id,
                        title: offer.title,
                        logo: offer.logo,
                        is_connect: !offer.is_connect
                    }
                }
                return offer
            })

            return {
                data: data,
                pagination: prevState.pagination
            }
        })
    }

    function getData(page: number) {
        setRawData({data: [], pagination: {}})
        setIsLoading(true)

        ENDPOINTS.UNICOM_OFFERS.GET(page, DATA_PAGE_LIMIT, searchString).then((res) => {
            setRawData(res)
            setIsLoading(false)
        })
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>Добавить оффер | Shark Cpa</title>
            </Helmet>

            <PageHeader title={"Добавить оффер"} childrenPosition={"end"}>
                <SearchInput onChange={(e) => setSearch(e.currentTarget.value)} value={search}/>
            </PageHeader>

            <div className={s.container__products}>
                <Table dataHeaders={headerData} className={s.container__products_table} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {rawData?.data.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow url={`https://my.x-partners.com/v2/offer/1608/${item.id}/general`}
                                      gridWidthSize={tableGrid} hover={true}>
                                <TableColumn>
                                    <div>{item.id}</div>
                                </TableColumn>
                                <TableColumn>
                                    <img className={s.logo} src={item.logo} alt="Logo"/>
                                    <div className={s.title}>{item.title}</div>
                                </TableColumn>
                                <TableColumn>
                                    <button onClick={async (e) => {
                                        e.preventDefault()
                                        await createOrRemoveOffer(item.id)
                                    }} className={s.hoverItem}>
                                        {item.is_connect ? 'Удалить' : 'Добавить'}
                                    </button>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}

                </Table>

                {isLoading && <Loader/>}

                <TablePagination currentPage={currentPage} totalPages={Math.round(Number((rawData?.pagination.count || 0) / DATA_PAGE_LIMIT))} onPageChange={(page) => {
                    setCurrentPage(page)
                }}/>
            </div>
        </div>
    )
}
